import { Box, color, useColorMode } from 'deepstash-ui';
import useFinishedLoading from 'hooks/useFinishedLoading';
import React, { useEffect, useState } from 'react';
import useActivity from 'src/providers/hooks/useActivity';
import useAuth from 'src/providers/hooks/useAuth';
import styles from './FlipCorner.module.scss';

export const FLIP_CORNER_EDGE_SIZE_BASE = '1.5rem';
export const FLIP_CORNER_EDGE_SIZE_LG = '2rem';

interface OwnProps {
  id: number;
  foldedRadiusSize?: string;
  /**
   * Wether to have a static flipCorner that is not interactable
   */
  isNotInteractable?: boolean;
}

const FlipCorner: React.FC<OwnProps> = ({
  id,
  foldedRadiusSize,
  isNotInteractable,
}) => {
  const { invertedColorMode } = useColorMode();
  const { activity } = useActivity();

  const { isLoggedIn } = useAuth();
  const savedAndInteractible =
    !isNotInteractable && isLoggedIn && activity?.stashedIdeasIds?.includes(id);

  const finished = useFinishedLoading();

  const [flipCornerBottomLeft, setFlipCornerBottomLeft] = useState(
    savedAndInteractible
      ? styles.initial_saved_bottom_left
      : styles.initial_unsaved_bottom_left,
  );

  const [flipCornerTopRight, setFlipCornerTopRight] = useState(
    savedAndInteractible
      ? styles.initial_saved_top_right
      : styles.initial_unsaved_top_right,
  );

  useEffect(() => {
    if (finished) {
      setFlipCornerBottomLeft(
        savedAndInteractible
          ? styles.saved_bottom_left
          : styles.unsaved_bottom_left,
      );
      setFlipCornerTopRight(
        savedAndInteractible
          ? styles.saved_top_right
          : styles.unsaved_top_right,
      );
    }
  }, [savedAndInteractible]);

  const getClipPath = (size: string) =>
    `polygon(0% 0, 0 0%, calc(100% - ${size}) 0%, 100% ${size}, 100% 100%, 100% 100%, 0 100%, 0 100%);`;

  return (
    <Box
      position="absolute"
      right={0}
      top={0}
      zIndex="1"
      width={{ base: FLIP_CORNER_EDGE_SIZE_BASE, lg: FLIP_CORNER_EDGE_SIZE_LG }}
      height={{
        base: FLIP_CORNER_EDGE_SIZE_BASE,
        lg: FLIP_CORNER_EDGE_SIZE_LG,
      }}
      // We cut from the top-right corener from the card when saved
      clipPath={{
        base: savedAndInteractible
          ? getClipPath(FLIP_CORNER_EDGE_SIZE_BASE)
          : '',
        lg: savedAndInteractible ? getClipPath(FLIP_CORNER_EDGE_SIZE_LG) : '',
      }}
    >
      {savedAndInteractible && (
        <Box
          w="100%"
          h="100%"
          position="absolute"
          borderTopRightRadius="1.25rem"
          className={flipCornerTopRight}
        />
      )}
      <Box
        w="100%"
        h="100%"
        backgroundColor={color[invertedColorMode].underground}
        position="absolute"
        borderBottomLeftRadius={foldedRadiusSize || '1rem'}
        className={flipCornerBottomLeft}
      />
    </Box>
  );
};

export default FlipCorner;
