import {
  Box,
  BoxProps,
  color,
  Flex,
  Link,
  PrimaryHeading,
  useColorMode,
  useIsMobileView,
} from 'deepstash-ui';
import useLocation from 'hooks/useLocation';
import useAuth from 'providers/hooks/useAuth';
import React, { useCallback, useState } from 'react';
import NextImage from 'src/components/image/NextImage';
import { Idea } from 'types/models';
import { getPixelWidthFromColumns, slugify } from 'utils/global';
import styles from './IdeaCard.module.scss';
import { useStylePropsForText } from './useIdeaCardContentUtils';

interface IdeaCardContentImage {
  url?: string;
  headingSize: 'h1' | 'h2' | 'h3' | 'h4';
  idea: Idea;
  preloadImage?: boolean;
  size?: 'sm' | 'md';
  /**
   * Whether to have a static ideaCard that is not interactable
   */
  isNotInteractable?: boolean;
  shouldTrim?: boolean;
  hideImage?: boolean;
  disableTitleHref?: boolean;
}

const IdeaCardContentImage: React.FC<IdeaCardContentImage> = ({
  headingSize,
  idea,
  preloadImage,
  size,
  isNotInteractable,
  shouldTrim,
  disableTitleHref,
}) => {
  const { colorMode } = useColorMode();

  const isMobileView = useIsMobileView();
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  const { image, title, imageBlurhash } = idea;

  const [imageData, setImageData] = useState<{
    naturalWidth: number;
    naturalHeight: number;
  }>();

  // We no longer trim idea card content
  const { titleTextSize: textSize } = useStylePropsForText(
    false,
    size,
    preloadImage,
  );

  const renderTitleText = () => {
    const renderedTitle = (
      <PrimaryHeading
        type="bold"
        size={headingSize}
        {...textSize}
        color={color[colorMode].text}
        textAlign="left"
        wordBreak="break-word"
        marginBottom={isMobileView ? '0.5rem' : '1rem'}
      >
        {title}
      </PrimaryHeading>
    );

    return !isLoggedIn &&
      !disableTitleHref &&
      (shouldTrim || location === 'article' || location === 'collection') ? (
      <Link
        _hover={undefined}
        href={`/idea/${idea.id}/${slugify(idea.title, idea.content)}`}
      >
        {renderedTitle}
      </Link>
    ) : (
      renderedTitle
    );
  };

  const imageWrapperStyle: BoxProps = {
    // The height is different depending on whether it's on mobile
    // or desktop, but it has no resize listener
    height: imageData
      ? isMobileView
        ? `calc(${
            imageData.naturalHeight / imageData.naturalWidth
          } * (100vw - 2rem))`
        : (imageData.naturalHeight / imageData.naturalWidth) *
          getPixelWidthFromColumns(6)
      : '300px',
    width: '100%',
    position: 'relative',
    borderTopRadius: '1rem',
    overflow: 'hidden',
  };

  const renderIdeaImage = useCallback(
    () => (
      <NextImage
        nextImageProps={{
          onLoadingComplete: img => {
            setImageData(img);
          },
        }}
        wrapperStyle={imageWrapperStyle}
        imageUrl={image}
        alt={title}
        blurhash={imageBlurhash}
        priority={preloadImage}
        imageModal="rect"
        isNotInteractable={isNotInteractable}
        imageClassName={styles.next_image_idea}
      />
    ),
    [image, imageData, isNotInteractable],
  );

  return (
    <Flex h="100%" flexDir="column" flexGrow={1}>
      {renderIdeaImage()}
      {title && (
        <Box
          px={{ base: '1.5rem', lg: '2rem' }}
          pt={{ base: '1rem', lg: '1.5rem' }}
        >
          {renderTitleText()}
        </Box>
      )}
    </Flex>
  );
};
export default IdeaCardContentImage;
