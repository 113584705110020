import { useCallback, useEffect, useState } from 'react';
import { Idea } from 'types/models';
import { ObservableSubscriber } from 'utils/global';
import useSourceEditor from './useSourceEditor';
/**
 * This hook handles getting ideas from a SourceProvider
 * It handles automatically subscribing and unsubscribing from Observables
 * It always returns the latest values of an idea
 * @param ideaId The key of the idea we want to use
 * @param defaultValue A fallback value in case no idea is found with that key
 */
const useOwnIdea = (
  ideaId: number,
  defaultValue?: Idea,
): Readonly<Idea> | undefined => {
  const { ideas } = useSourceEditor();
  const observable = ideas.find(idea => idea.Data.localId === ideaId);

  const [value, setValue] = useState<Readonly<Idea> | undefined>(
    observable?.Data,
  );
  const subscriber: ObservableSubscriber<Idea> = useCallback(newValue => {
    setValue({ ...newValue });
  }, []);
  useEffect(() => {
    const observable = ideas.find(idea => idea.Data.localId === ideaId);
    observable?.subscribe(subscriber);
    return () => {
      observable?.unsubscribe(subscriber);
    };
  }, [ideaId, ideas]);

  return value ?? defaultValue;
};

export default useOwnIdea;
