import {
  DraftContext,
  SourceProvideContextType,
  SourceProviderAction,
  SourceProviderAsyncActions,
  SourceProviderExtra,
  SourceProviderHiddenAction,
  SourceProviderState,
} from '../source';
import useCreateContextConsumer from './useCreateContextConsumer';
import * as asyncActions from '../source/SourceEditorActions';

const useSourceEditor = () => {
  const { dispatch, ...contextConsumer } = useCreateContextConsumer<
    //We don't need to expose that property directly
    //We have the `isIdeaFocused` function
    Omit<SourceProviderState, 'focusedDraft'>,
    SourceProviderAction,
    SourceProvideContextType,
    SourceProviderHiddenAction,
    SourceProviderAsyncActions,
    SourceProviderExtra
  >({ context: DraftContext, asyncActions, withLogging: true });

  return {
    ...contextConsumer,
    sourceDispatch: dispatch,
  };
};

export default useSourceEditor;
