import { DraftsError, Idea, IdeaType, Source, SourceType } from 'types';
import { ideaHasContent, stripHTMLTags } from 'utils/html-utils';
import { CONTENT_LENGTH_LIMIT } from 'utils/constants';
import { getRandomNegativeInt, Observable } from './global';

/**
 * Check if the idea is valid (it has content within the limits and author if quote)
 */
export const isIdeaInvalid = (idea: Idea): boolean => {
  if (idea.type === IdeaType.IMAGE) {
    return !idea.image;
  }

  //Get the length of the plain text content
  const content = stripHTMLTags(idea.content);

  //Invalid content length
  if (content.length === 0 || content.length > CONTENT_LENGTH_LIMIT) {
    return true;
  }

  //Last check. If author_name is an empty string it means it's a quote without an author
  return idea.type === IdeaType.QUOTE && !idea.authorName;
};

/**
 * Gets the number of valid ideas using invalidIdea function
 * @param ideas List of Ideas you want to check
 * @returns number of valid ideas
 */
export const getValidIdeaCount = ({
  ideas,
  ideaObservables,
}: {
  ideas?: Idea[];
  ideaObservables?: Observable<Idea>[];
}) => {
  if (ideas) {
    const validIdeas = ideas.reduce((acc, idea) => {
      if (isIdeaInvalid(idea)) {
        return acc;
      } else {
        return acc + 1;
      }
    }, 0);
    return validIdeas;
  } else if (ideaObservables) {
    const validIdeas = ideaObservables.reduce((acc, idea) => {
      if (isIdeaInvalid(idea.Data)) {
        return acc;
      } else {
        return acc + 1;
      }
    }, 0);
    return validIdeas;
  } else return 0;
};

/**
 * function that decides what error toast should be shown when pressing the disabled publish button
 * @param ideas the ideas inside the source
 * @param source the source to be published
 * @returns DraftsError type
 */
export const getSourceDraftsError = (
  ideas: Observable<Idea>[],
  source: Source | null,
): DraftsError => {
  if (!source || source.sourceType === SourceType.UNKNOWN) {
    return DraftsError.NO_SOURCE;
  }

  if (source.title === '') {
    return DraftsError.NO_TITLE;
  }

  let error: DraftsError | undefined = undefined;

  const emptyIdeas = ideas.filter(idea =>
    idea.Data.type !== IdeaType.IMAGE
      ? !ideaHasContent(idea.Data.content)
      : !idea.Data.image,
  ).length;
  if (emptyIdeas === ideas.length) {
    return DraftsError.NO_IDEAS;
  }

  ideas
    .map(({ Data: idea }) => idea)
    .forEach(idea => {
      // optimize the function. once an error is chosen, don't bother continue checking
      if (error !== undefined) return;

      if (
        (idea.type === IdeaType.IMAGE && !idea.image) ||
        (idea.type !== IdeaType.IMAGE && !ideaHasContent(idea.content))
      ) {
        error = DraftsError.NO_CONTENT;
        return;
      }

      if (idea.type === IdeaType.QUOTE && idea.authorName?.trim() === '') {
        error = DraftsError.NO_AUTHOR;
        return;
      }

      if (stripHTMLTags(idea.content).length > CONTENT_LENGTH_LIMIT) {
        error = DraftsError.CHARS_LIMIT;
        return;
      }
    });

  if (error === undefined) {
    error = DraftsError.NO_ERROR;
  }

  return error;
};

class IdeaIdManager {
  //Keep track of all assigned local ids so far
  // When we send the create req to the backend, the id is marked as posted
  private _ids: Record<number, 'registered' | 'posted'> = {};
  getId = () => {
    while (true) {
      const id = getRandomNegativeInt();
      if (!this._ids[id]) {
        this._ids[id] = 'registered';
        return id;
      }
    }
  };

  markIdAsPosted = (id?: number) => {
    if (id) {
      this._ids[id] = 'posted';
    }
  };

  canPostId = (id?: number) => {
    return id && this._ids[id] === 'registered';
  };
}

export const ideaIdManager = new IdeaIdManager();
