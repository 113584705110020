import { PrimaryHeadingSize, typography, useIsMobileView } from 'deepstash-ui';
import { useMemo } from 'react';

export const useStylePropsForQuote = (
  preloadImage?: boolean,
  isIdeaTrimmed?: boolean,
  size?: 'sm' | 'md',
) => {
  const isMobileView = useIsMobileView();

  const imageSize = useMemo(() => {
    switch (size) {
      case 'sm': {
        return '4rem';
      }
      default: {
        if (isIdeaTrimmed) {
          if (isMobileView) {
            return '4.75rem';
          } else {
            return '6.25rem';
          }
        } else {
          //Make the image 1 px taller to trick google =))
          if (preloadImage) {
            if (isMobileView) {
              return '6.3125rem';
            } else {
              return '8.8125rem';
            }
          } else {
            if (isMobileView) {
              return '6.25rem';
            } else {
              return '8.75rem';
            }
          }
        }
      }
    }
  }, [isIdeaTrimmed, isMobileView, preloadImage]);

  const style = useMemo(() => {
    switch (size) {
      case 'sm': {
        return {
          imageSize,
          titleTextSize: {
            textSize: 's' as PrimaryHeadingSize,
            fontSize: '1rem',
            lineHeight: '1.5rem',
          },
          contentTextSize: typography.primaryTextFonts.small.italic,
        };
      }
      default: {
        /**
         * Default is md
         */
        return {
          imageSize,
          titleTextSize: {
            textSize: 's' as PrimaryHeadingSize,
          },
          contentTextSize:
            isMobileView || isIdeaTrimmed
              ? typography.primaryTextFonts.medium.italic
              : typography.primaryTextFonts.large.italic,
        };
      }
    }
  }, [size, isIdeaTrimmed, isMobileView]);

  return style;
};

export const useStylePropsForText = (
  isIdeaTrimmed?: boolean,
  size?: 'sm' | 'md',
  preloadImage?: boolean,
) => {
  const isMobileView = useIsMobileView();

  const imageHeight = useMemo(() => {
    switch (size) {
      case 'sm': {
        return '5rem';
      }
      default: {
        //Make the image 1 px taller to trick google =))
        if (preloadImage) {
          if (isMobileView) {
            return '7.5625rem';
          } else {
            return '10.0625rem';
          }
        } else {
          if (isMobileView) {
            return '7.5rem';
          } else {
            return '10rem';
          }
        }
      }
    }
  }, [preloadImage, isMobileView, size]);

  const style = useMemo(() => {
    switch (size) {
      case 'sm': {
        return {
          imageHeight,
          titleTextSize: {
            textSize: 's' as PrimaryHeadingSize,
            fontSize: '1rem',
            lineHeight: '1.5rem',
          },
          contentTextSize: typography.primaryTextFonts.small.regular,
        };
      }
      default: {
        /**
         * default is md
         */
        return {
          imageHeight,
          titleTextSize: {
            textSize: (isMobileView ? 's' : 'm') as PrimaryHeadingSize,
          },
          contentTextSize:
            isMobileView || isIdeaTrimmed
              ? typography.primaryTextFonts.medium.regular
              : typography.primaryTextFonts.large.regular,
        };
      }
    }
  }, [isMobileView, size, isIdeaTrimmed]);

  return style;
};
