import useProfile from 'src/providers/hooks/useProfile';
import { useEffect, useRef } from 'react';

export default function useFinishedLoading(): boolean {
  const finished = useRef<boolean>(false);
  const { profile } = useProfile();

  useEffect(() => {
    if (profile) {
      finished.current = true;
    }
  }, [profile]);

  return finished.current;
}
