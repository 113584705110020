import * as Sentry from '@sentry/nextjs';
import { Severity } from '@sentry/nextjs';
import usePrevious from 'hooks/usePrevious';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { Idea, IdeaSyncStatus, Source } from 'types/models';
import { getObjectDiff } from 'utils/global';
import { syncIdeas } from './SourceEditorActions';
import {
  SourceProvideContextType,
  SourceProviderReducer,
  SourceProviderState,
} from './SourceEditorProviderTypes';
import sourceReducer from './SourceEditorReducer';

const INITIAL_VALUE: SourceProviderState = {
  ideas: [],
  source: null,
  sourceContextEditorRef: undefined,
  sourceTitleEditorRef: undefined,
  sourceHashtags: [],
  validIdeasCount: 0,
};

export const DraftContext = createContext<SourceProvideContextType>({
  dispatch: () => {
    return;
  },
  state: INITIAL_VALUE,
  extra: {
    isIdeaFocused: () => false,
  },
});

DraftContext.displayName = 'DraftContext';

interface DraftProviderProps {
  draft: Source | null;
}

const DraftProvider: React.FC<DraftProviderProps> = ({
  draft: source,
  children,
}) => {
  const [state, dispatch] = useReducer<SourceProviderReducer>(sourceReducer, {
    ...INITIAL_VALUE,
  });

  const prevSource = usePrevious(state.source);
  const prevSourceHashtags = usePrevious(state.sourceHashtags);
  const prevValidIdeasCount = usePrevious(state.validIdeasCount);

  // const { isOpen: isCelebrationModalOpen, onClose: onCelebrationModalClose } =
  //   useDisclosure();

  //Sync the props with the state
  useEffect(() => {
    dispatch({
      type: 'set-source',
      payload: {
        source,
      },
    });
  }, [source?.id]);

  const showPopup = useCallback(
    (e: any) => {
      const unsavedIdeaCount = state.ideas
        .map(idea => idea.Data)
        .reduce(
          (acc, idea) =>
            idea.syncStatus !== IdeaSyncStatus.SYNCED ? acc + 1 : acc,
          0,
        );

      //Sync the ideas before unload
      if (state.source) {
        syncIdeas(dispatch, {
          ideas: state.ideas.map(({ Data }) => Data),
          sourceId: state.source?.id,
        });
      }
      if (unsavedIdeaCount > 0) {
        e.preventDefault();
        e.stopPropagation();
        e.returnValue = 'You have unstashed ideas';
      }
    },
    [state.ideas],
  );

  const isIdeaFocused = useCallback(
    (idea?: Idea) => !!idea && idea.localId === state.focusedDraft,
    [state.focusedDraft],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', showPopup);

    return () => {
      window.removeEventListener('beforeunload', showPopup);
    };
  }, [showPopup]);

  // // Read auto-increment
  // const { setInsights } = useContext(IntersectionObserverContext);

  // useEffect(() => {
  //   if (state.source?.status !== ArticleStatus.DRAFT) {
  //     setInsights(state.ideas.map(idea => idea.Data));
  //   }
  // }, [state.source?.status, state.ideas, setInsights]);

  const contextValue = useMemo<SourceProvideContextType>(
    () => ({
      dispatch,
      state,
      extra: { isIdeaFocused },
    }),
    [dispatch, state, isIdeaFocused],
  );

  // Logs
  useEffect(() => {
    //Log the differences
    if (!prevSource || !state.source) {
      Sentry.addBreadcrumb({
        data: state.source ?? { data: 'Null source' },
        category: 'source',
        level: Severity.Log,
        message: `Initial source ${source?.id}`,
      });
      return;
    }
    const diff = getObjectDiff(state.source, prevSource);

    Sentry.addBreadcrumb({
      data: diff,
      category: 'source',
      level: Severity.Log,
      message: `Source updated ${source?.id}`,
    });
  }, [state.source]);

  useEffect(() => {
    //Log the differences
    if (prevSourceHashtags !== state.sourceHashtags) {
      Sentry.addBreadcrumb({
        data: state.sourceHashtags,
        category: 'source',
        level: Severity.Log,
        message: `Source hashtags updated ${source?.id}`,
      });
    }
  }, [state.sourceHashtags]);

  useEffect(() => {
    if (state.validIdeasCount !== prevValidIdeasCount) {
      Sentry.addBreadcrumb({
        data: state.sourceHashtags,
        category: 'source',
        level: Severity.Log,
        message: `Source hashtags updated ${source?.id}`,
      });
    }
  }, [state.validIdeasCount]);

  return (
    <DraftContext.Provider value={contextValue}>
      {/* {isCelebrationModalOpen && source && (
        <PublishCelebrationModal
          isOpen={isCelebrationModalOpen}
          onClose={onCelebrationModalClose}
          article={source}
        />
      )} */}
      {children}
    </DraftContext.Provider>
  );
};

export default DraftProvider;
