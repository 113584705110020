import {
  deleteArticle,
  getArticleByInsightId,
  reorderArticleInsights,
  updateArticleData,
} from 'api/Article';
import {
  deleteInsight as deleteIdeaApiCall,
  deleteInsight as deleteInsightApiCall,
} from 'api/Insight';
import { ArticleApiResponse } from 'api/api.types';
import { ArticleStatus } from 'types/enums';
import { Idea, IdeaSyncStatus, Source } from 'types/models';
import { AsyncAction } from '../types/actions.types';
import {
  UpdateImageArgs,
  DeleteDraftArgs,
  DeleteIdeaArgs,
  SourceProviderHiddenAction,
  SyncIdeaArgs,
  SyncIdeasArgs,
  UpdateContextArgs,
  UpdateIdeaArgs,
  UpdateTitleArgs,
  UpdateIdeaOrderArgs,
} from './SourceEditorProviderTypes';
import {
  DebounceIdeaSyncManagerInstance,
  isLocalIdea,
  syncIdeaUtil,
  syncIdeasUtil,
} from './utils/SourceProviderUtils';

type IdeaCreationResData = Map<
  number,
  {
    id: number;
    created_at: string;
    user?: string;
  }
>;

export const deleteIdea: AsyncAction<
  SourceProviderHiddenAction,
  DeleteIdeaArgs
> = async (dispatch, { codeSource, idea }) => {
  const ideaId = idea.id;

  if (ideaId < 0) {
    //This idea doesn't exist on the backend
    //Just delete it from the UI
    dispatch({
      type: 'delete-idea/success',
      payload: {
        ideaId: idea.id,
      },
    });
    return;
  }

  let article: ArticleApiResponse | null = null;
  if (ideaId) article = await getArticleByInsightId(ideaId);

  if (
    (article && article.status === ArticleStatus.DRAFT) ||
    article?.blocks?.length !== 1
  ) {
    //Deleting this idea shouldn't result in the deletion of the source
    if (ideaId && ideaId > 0) deleteInsightApiCall(ideaId, codeSource);
    dispatch({
      type: 'delete-idea/success',
      payload: {
        ideaId: idea.id,
      },
    });
    return;
  }

  //If we haven't returned yet, this is the last idea of a published article
  // The whole source should be deleted
  if (article) {
    deleteArticle(article.id);
    // Also delete the ideas
    if (ideaId && ideaId > 0) deleteInsightApiCall(ideaId, codeSource);
    dispatch({
      type: 'delete-idea/success',
      payload: {
        ideaId: idea.id,
      },
    });
  }

  return;
};

/**
 * Action to sync the local changes to an idea to the backend
 * If the idea doesn't yet exist on the backend, it will be created the first time this action gets dispatched
 * @param dispatch The dispatch function for the Source Provider
 * @param idea The fields of the idea that got updated.
 * If it's a creation, it's the whole idea.
 * The partial should always contain the localId to identify the idea locally
 * @param sourceId The source to which this idea belongs to
 * @param onSuccess A callback that is called after the creation/update was successful.
 * If it was a creation it will get the id of the idea as a parameter
 * @param userProfile The profile of the current user to use as the user_info for the newly created idea
 * @param updateTimestamp A timestamp for this update to ensure we don't overwrite updates with older ones
 */

export const syncIdea: AsyncAction<
  SourceProviderHiddenAction,
  SyncIdeaArgs,
  Promise<boolean>
> = async (
  dispatch,
  { idea, sourceId, onSuccess, userProfile, updateTimestamp },
) => {
  // A function to get called after the API call to update/create the idea returned
  const onSyncSuccess = (
    ideaId: number,
    created_at?: string,
    user?: string,
    source?: Source,
  ) => {
    // If we don't have an id present in the Partial<Idea> to identify the idea there is nothing we can do with the returned data
    if (!idea.id || !idea.localId) {
      return;
    }
    if (created_at) {
      //This was a creation
      dispatch({
        type: 'sync-idea/success',
        payload: {
          source,
          createdAt: created_at,
          id: ideaId,
          localIdeaId: idea.localId,
          userId: user ? Number.parseInt(user) : undefined,
        },
      });

      onSuccess?.(ideaId);
    } else {
      //This was update
      dispatch({
        type: 'sync-idea/success',
        payload: {
          localIdeaId: idea.localId,
        },
      });
      onSuccess?.();
    }
  };

  const onSyncLoading = () => {
    if (!idea.localId) {
      return;
    }
    dispatch({
      type: 'sync-idea/loading',
      payload: {
        localIdeaId: idea.localId,
      },
    });
  };

  // Make the API call to update / create the idea
  const [reQueue, sourceCreated] = await syncIdeaUtil(
    idea,
    userProfile,
    sourceId,
    onSyncSuccess,
    onSyncLoading,
  );
  if (reQueue) {
    //This update can't be processed now, re-queue it
    // The idea cast is not valid, but it has all the fields required for a re-queue since it came from the syncManager in the first place
    DebounceIdeaSyncManagerInstance.syncIdea(
      dispatch,
      idea as Idea,
      userProfile,
      updateTimestamp,
      sourceId,
    );
  }
  return sourceCreated;
};

/**
 * Action to sync a list of ideas to the backend at once
 * @param dispatch The source provider dispatch function
 * @param ideas The ideas we want to sync
 * @param sourceId The source those ideas belong to
 */
export const syncIdeas: AsyncAction<
  SourceProviderHiddenAction,
  SyncIdeasArgs
> = async (dispatch, { ideas, sourceId, onNewIdeaCreationSuccess }) => {
  // All the unsaved ideas that are only local
  const unsavedIdeasLocalIds: number[] = [];
  const newIdeasData: IdeaCreationResData = new Map();

  //This will be the onSuccess callback for the sync util. If the idea was just created we'll collect the API response into a map
  const collectNewIdeaData = (
    localIdeaId: number,
    id: number,
    created_at: string,
    user?: string,
  ) => {
    // Collect the id and the created_at data for the idea with this localId in a map
    newIdeasData.set(localIdeaId, {
      created_at,
      id,
      user,
    });
    onNewIdeaCreationSuccess?.({ ideaId: id });
  };
  for (const idea of ideas) {
    if (idea.syncStatus === IdeaSyncStatus.NOT_SYNCED) {
      unsavedIdeasLocalIds.push(idea.localId);
    }

    //Make a loading dispatch for the ideas that are just now created on the backend
    dispatch({
      type: 'sync-ideas/loading',
      payload: {
        localIdeaIds: unsavedIdeasLocalIds,
      },
    });

    //Sync the ideas
    await syncIdeasUtil({ ideas, sourceId, onSuccess: collectNewIdeaData });

    dispatch({
      type: 'sync-ideas/success',
      payload: {
        localIdeaIds: ideas.map(idea => idea.localId),
        newIdeas: newIdeasData,
      },
    });
  }
};

/**
 * Prior to 2023, this call could result in the creation of a draft
 * After the 2023 update, we always create a draft first,
 * so this is not the case anymore.
 */
export const updateIdea: AsyncAction<
  SourceProviderHiddenAction,
  UpdateIdeaArgs
> = async (
  dispatch,
  { idea, newValue, sourceId, canIdeaAutosave, userProfile },
) => {
  const changedProperties = Object.entries(newValue).reduce(
    (acc, [key, value]) => {
      if (idea && (idea as any)[key] !== value) {
        return acc + 1;
      } else {
        return acc;
      }
    },
    0,
  );
  // No properties changed
  if (changedProperties === 0) return;

  if (idea) {
    //Update the idea locally and prepare to save it to the backend
    const updatedIdea = { ...idea, ...newValue };
    dispatch({
      type: 'update-idea/success',
      payload: {
        localIdea: idea.localId,
        newValue,
      },
    });
    if (canIdeaAutosave) {
      //If the idea is already created on the backend, this will be a PATCH so send only the relevant bits
      await DebounceIdeaSyncManagerInstance.syncIdea(
        dispatch,
        updatedIdea,
        userProfile,
        new Date(),
        sourceId,
        !isLocalIdea(idea.id) ? newValue : undefined,
      );
    }
  }
};

export const deleteDraft: AsyncAction<
  SourceProviderHiddenAction,
  DeleteDraftArgs
> = async (
  dispatch,
  { activityDispatch, sourceId, ideaIds, isOpenedDraft },
) => {
  //Delete the source on the backend
  deleteArticle(sourceId);
  // Also delete the ideas
  ideaIds.forEach(ideaId => {
    if (ideaId && ideaId > 0) deleteIdeaApiCall(ideaId, 'draft-delete');
  });

  //Update the activity slice
  activityDispatch({
    type: 'delete-draft/success',
    payload: {
      id: sourceId,
    },
  });

  if (isOpenedDraft)
    // We deleted the currently opened article so dispatch this action to clear the SourceProvider
    dispatch({
      type: 'delete-draft/success',
    });
};

export const updateImage: AsyncAction<
  SourceProviderHiddenAction,
  UpdateImageArgs
> = async (dispatch, { sourceId, image }) => {
  return await updateArticleData({ sourceId, image })
    .then(() => {
      dispatch({
        type: 'update-image/success',
        payload: {
          image,
        },
      });
      return Promise.resolve();
    })
    .catch(e => {
      console.log(e);
      return Promise.reject(e);
    });
};

export const updateTitle: AsyncAction<
  SourceProviderHiddenAction,
  UpdateTitleArgs
> = async (dispatch, { sourceId, title }) => {
  return await updateArticleData({ sourceId, title })
    .then(() => {
      dispatch({
        type: 'update-title/success',
        payload: {
          title,
        },
      });
      return Promise.resolve();
    })
    .catch(e => {
      console.log(e);
      return Promise.reject(e);
    });
};

export const updateContext: AsyncAction<
  SourceProviderHiddenAction,
  UpdateContextArgs
> = async (dispatch, { sourceId, context }) => {
  return await updateArticleData({ sourceId, context })
    .then(() => {
      dispatch({
        type: 'update-context/success',
        payload: {
          context,
        },
      });
      return Promise.resolve();
    })
    .catch(e => {
      console.log(e);
      return Promise.reject(e);
    });
};

export const updateIdeaOrder: AsyncAction<
  SourceProviderHiddenAction,
  UpdateIdeaOrderArgs
> = async (
  dispatch,
  { sourceId, initialIdeasById, reorderedIdeasById, status },
) => {
  dispatch({
    type: 'update-idea-order/success',
    payload: {
      ideasById: reorderedIdeasById,
      status,
    },
  });
  return await reorderArticleInsights({
    sourceId,
    ideasById: reorderedIdeasById,
  })
    .then(() => {
      return Promise.resolve();
    })
    .catch(e => {
      console.log('error updating idea order');
      dispatch({
        type: 'update-idea-order/success',
        payload: {
          ideasById: initialIdeasById,
          status,
        },
      });
      console.log(e);
      return Promise.reject(e);
    });
};
