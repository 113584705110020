import useActivity from 'src/providers/hooks/useActivity';
import { useMemo } from 'react';

/**
 * TODO needs to be in a subfolder
 * @param id
 */
export const useIsIdeaStashed = (id: number): boolean => {
  const { activity } = useActivity();

  const currentSelectedStashId = useMemo(
    () => activity?.stashedIdeas?.find(x => x[0] === id)?.[1],
    [activity?.stashedIdeas, id],
  );

  const isStashed = useMemo(() => {
    return (
      (activity?.stashedIdeasIds.includes(id) ?? false) &&
      //If this is an idea the current user published without a stash, it will appear in the stashed ideas ids, but won't have a stash associated
      currentSelectedStashId !== undefined
    );
  }, [activity?.stashedIdeasIds, id]);

  return isStashed;
};
