import { updateIndependentArticle, updateSourceHashtags } from 'api/Article';
import { ArticleStatus, IdeaType } from 'types/enums';

import { Idea, IdeaSyncStatus } from 'types/models';
import { Observable } from 'utils/global';
import { ideaIdManager } from 'utils/idea-utils';
import { SourceProviderReducer } from './SourceEditorProviderTypes';
import { isLocalIdea } from './utils/SourceProviderUtils';

const sourceReducer: SourceProviderReducer = (state, action) => {
  switch (action.type) {
    case 'create-idea': {
      const { ideaStatus, userId, content, type } = action.payload;
      // Get the index of this idea
      const index = Math.max(
        ...state.ideas.map(ideaObservable => ideaObservable.Data.order),
        0,
      );
      const ideas = [...state.ideas];

      const newIdeaId = ideaIdManager.getId();

      ideas.push(
        new Observable<Idea>({
          content: content ?? '<p></p>',
          createdAt: '',
          // If the id is negative => the idea is locally created
          // the id will become positive once the backend saved the idea
          id: newIdeaId,
          localId: newIdeaId,
          order: index + 1,
          topics: [],
          totalSaves: 1,
          totalReads: 0,
          // [Like functionality]
          // totalLikes: 0,
          status: ideaStatus,
          isDraft: true,
          syncStatus: IdeaSyncStatus.NOT_SYNCED,
          timeToRead: 0,
          title: '',
          userId,
          type: type ?? IdeaType.DEFAULT,
        }),
      );
      return {
        ...state,
        ideas,
      };
    }
    case 'delete-idea/success': {
      const { ideaId } = action.payload;

      const updatedIdeas = state.ideas.filter(
        ideaObservable => ideaObservable.Data.id !== ideaId,
      );

      return {
        ...state,
        ideas: updatedIdeas,
      };
    }
    case 'sync-idea/loading': {
      const { localIdeaId } = action.payload;
      state.ideas
        .find(idea => idea.Data.localId === localIdeaId)
        ?.updateData(_ => ({
          syncStatus: IdeaSyncStatus.SYNCING,
        }));

      return state;
    }
    case 'sync-idea/success': {
      //Update the state of the idea
      const { localIdeaId, id, createdAt, userId, source } = action.payload;
      const idea = state.ideas.find(idea => idea.Data.localId === localIdeaId);
      if (idea) {
        // Here we check the id since even after the idea got synced on the backend, the localId can still be negative
        if (!isLocalIdea(idea.Data.id)) {
          idea.updateData({
            syncStatus: IdeaSyncStatus.SYNCED,
          });
        } else {
          //The idea just got created on the backend so let's add the id to it
          idea.updateData({
            id,
            localId: id,
            createdAt,
            userId,
            syncStatus: IdeaSyncStatus.SYNCED,
          });
        }
      }
      if (source) {
        return { ...state, source };
      } else if (id) {
        //When an idea gets the id from the backend we need to return a new state so the id change is handled correctly
        return { ...state };
      } else {
        return state;
      }
    }
    case 'toggle-is-idea-draft': {
      const { localIdeaId } = action.payload;
      //Update the idea state
      const idea = state.ideas.find(idea => idea.Data.localId === localIdeaId);
      idea?.updateData({
        isDraft: !idea.Data.isDraft,
      });

      return state;
    }
    case 'update-idea/success': {
      const { localIdea, newValue } = action.payload;
      //Update the idea
      const idea = state.ideas.find(idea => idea.Data.localId === localIdea);
      //This idea is now not synced to the backend
      idea?.updateData({ ...newValue, syncStatus: IdeaSyncStatus.NOT_SYNCED });
      return state;
    }
    case 'change-idea-type': {
      const { localIdeaId, ideaType } = action.payload;
      const idea = state.ideas.find(idea => idea.Data.localId === localIdeaId);
      //Get the current type
      const isQuote = idea?.Data.type === IdeaType.QUOTE;
      idea?.updateData({
        authorName: isQuote ? undefined : '',
        title: isQuote ? '' : idea?.Data.title,
        type: ideaType,
      });

      return state;
    }
    case 'sync-ideas/success': {
      const { localIdeaIds, newIdeas } = action.payload;
      const ideas = state.ideas.filter(({ Data }) =>
        localIdeaIds.includes(Data.localId),
      );
      for (const idea of ideas) {
        if (newIdeas?.has(idea.Data.localId)) {
          //This idea was just created so let's update it accordingly
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const { id, created_at, user } = newIdeas!.get(idea.Data.localId)!;
          idea.updateData({
            syncStatus: IdeaSyncStatus.SYNCED,
            id,
            createdAt: created_at,
            userId: user ? Number.parseInt(user) : undefined,
          });
        }
        idea.updateData({
          syncStatus: IdeaSyncStatus.SYNCED,
        });
      }
      return state;
    }
    case 'sync-ideas/loading': {
      const { localIdeaIds } = action.payload;
      const ideas = state.ideas.filter(({ Data }) =>
        localIdeaIds.includes(Data.localId),
      );
      for (const idea of ideas) {
        idea.updateData({
          syncStatus: IdeaSyncStatus.SYNCING,
        });
      }
      return state;
    }
    case 'publish-source/success': {
      if (!state.source) {
        return state;
      }
      const { stash } = action.payload;

      //Mark all ideas as not drafts
      for (const idea of state.ideas) {
        idea.updateData({
          isDraft: false,
        });
      }

      return {
        ...state,
        source: {
          ...state.source,
          status: ArticleStatus.PUBLISHED,
          publishStash: stash,
        },
      };
    }
    case 'set-context-editor-ref': {
      const { contextEditorRef } = action.payload;
      return {
        ...state,
        sourceContextEditorRef: contextEditorRef,
      };
    }
    case 'set-title-editor-ref': {
      const { titleEditorRef } = action.payload;
      return {
        ...state,
        sourceTitleEditorRef: titleEditorRef,
      };
    }
    case 'set-source': {
      const { source } = action.payload;
      if (source) {
        //This is an already existing source so load the ideas
        return {
          source,
          //Init the local properties of ideas
          ideas: source.ideas
            .map(
              idea =>
                ({
                  ...idea,
                  autoSave: source.status === ArticleStatus.DRAFT,
                  draftFocus: false,
                  isDraft: source.status === ArticleStatus.DRAFT,
                  localId: idea.id,
                  syncStatus: IdeaSyncStatus.SYNCED,
                } as Idea),
            )
            .map(idea => new Observable(idea)),
          sourceHashtags: source.ideas[0]?.hashtags ?? [],
        };
      } else {
        // We return an empty state for when going back to the empty draft page
        return {
          source: null,
          ideas: [],
          sourceHashtags: [],
        };
      }
    }

    case 'update-idea-order/success': {
      if (!state.source) {
        return state;
      }

      const { ideasById } = action.payload;
      const sortedIdeasList: Idea[] = state.ideas.map(idea => {
        return {
          ...idea.Data,
          order: ideasById.indexOf(idea.Data.id) + 1,
        } as Idea;
      });

      sortedIdeasList.sort((a, b) => a.order - b.order);

      return {
        ...state,
        ideas: sortedIdeasList.map(idea => new Observable(idea)),
      };
    }

    case 'update-image/success': {
      if (!state.source) return state;
      const { image } = action.payload;
      return {
        ...state,
        source: {
          ...state.source,
          image,
        },
      };
    }

    case 'update-context/success': {
      if (!state.source) {
        return state;
      }
      const { context } = action.payload;
      return { ...state, source: { ...state.source, context } };
    }

    case 'update-title/success': {
      if (!state.source) {
        return state;
      }
      const { title } = action.payload;
      return { ...state, source: { ...state.source, title } };
    }

    case 'update-independent-source': {
      if (!state.source) {
        return state;
      }
      const { title, description } = action.payload;
      updateIndependentArticle(state.source.id, title, description);
      return { ...state, source: { ...state.source, title, description } };
    }
    case 'delete-draft/success': {
      return {
        source: null,
        ideas: [],
        sourceHashtags: [],
        focusedDraft: undefined,
      };
    }

    case 'import-source/success': {
      const { source } = action.payload;
      return {
        ...state,
        source,
      };
    }
    case 'set-focused-draft': {
      const { isFocused, localIdeaId } = action.payload;
      if (isFocused) {
        return { ...state, focusedDraft: localIdeaId };
      } else if (state.focusedDraft === localIdeaId) {
        return { ...state, focusedDraft: undefined };
      } else return state;
    }
    case 'update-source-hashtags': {
      if (!state.source) {
        return state;
      }
      const { hashtags } = action.payload;

      //We don't need anything from the API
      updateSourceHashtags(hashtags, state.source.id);
      for (const idea of state.ideas) {
        idea.updateData({ hashtags });
      }
      return {
        ...state,
        sourceHashtags: hashtags,
      };
    }
    case 'update-valid-ideas-count': {
      const { validIdeasCount } = action.payload;
      return {
        ...state,
        validIdeasCount,
      };
    }
    default: {
      return state;
    }
  }
};

export default sourceReducer;
