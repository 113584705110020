import useLocation from 'hooks/useLocation';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import useOwnIdea from 'src/providers/hooks/useOwnIdea';
import useProfile from 'src/providers/hooks/useProfile';
import useSourceEditor from 'src/providers/hooks/useSourceEditor';
import { HeadingSize, Idea, Source } from 'types';
import { IdeaData } from './IdeaCard';

export const useIdeaCardInternals = ({ data }: { data: IdeaData }) => {
  let source: Source | null, idea: Readonly<Idea | undefined>;

  // typeof data === 'number' means that the idea belongs to the current user
  // don't make a boolean constant with this, because the build will fail on type checking

  const localIdeaId = typeof data === 'number' ? data : -1;
  const ownIdea = useOwnIdea(localIdeaId);
  const { sourceDispatch, ideas, source: providerSource } = useSourceEditor();

  const router = useRouter();

  if (typeof data === 'number') {
    idea = ownIdea;
    source = providerSource;
  } else {
    idea = data.idea;
    source = data.source;
  }

  const path = router.asPath.split('/');
  const location = useLocation();
  const idParam = path[2];

  const isIdeaOnArticlePage =
    location === 'article' && idParam === source?.id.toString();

  const { profile } = useProfile();
  const shouldIdeaBeTrimmed =
    location === 'idea' && parseInt(router.query.id as string) !== idea?.id;
  const userId = profile?.id;

  const headingSize: HeadingSize = useMemo(() => {
    if (location === 'idea' && parseInt(router.query.id as string) === idea?.id)
      return 'h1';
    if (isIdeaOnArticlePage) return 'h2';
    if (path[1] === 'article' || path[1] === 'profile' || path[1] === '')
      return 'h4';
    if (path[1] === 'u') return 'h2';
    return 'h3';
  }, [isIdeaOnArticlePage, path]);

  //Set the draft state after a redirect with the edit param
  useEffect(() => {
    // only for own ideas
    if (typeof data === 'number') {
      const editRedirect =
        !!router.query.edit &&
        router.query.edit === idea?.id.toString() &&
        (location === 'article' || location === 'idea');
      const canEdit = userId && idea?.userId === userId;
      if (editRedirect && canEdit && !idea?.isDraft && ideas.length !== 0) {
        sourceDispatch({
          type: 'toggle-is-idea-draft',
          payload: {
            localIdeaId: data,
          },
        });
      }
    }
  }, [ideas]);

  return {
    headingSize,
    shouldIdeaBeTrimmed,
    idea,
    source,
  };
};
