import {
  createInsight,
  createMultipleIdeas,
  editInsight,
  editMultipleIdeas,
} from 'api/Insight';
import { Idea, IdeaSyncStatus, Source, UserProfile } from 'types/models';
import { ideaHasContent } from 'utils/html-utils';
import { syncIdea } from '../SourceEditorActions';
import { SourceProviderHiddenAction } from '../SourceEditorProviderTypes';
import { createUnknownSource } from 'api/Article';
import { normalizeUnknownSource } from 'utils/normalizers/source';
import { IdeaType } from 'types';
import { ideaIdManager } from 'utils/idea-utils';

/**
 * Create the payload for the idea creation API call
 * @param creationSourceId The id of the source of this idea
 * @param idea The contents of the idea
 */
export const getCreationPayload = (
  creationSourceId: number,
  idea: Partial<Idea>,
) => ({
  articleId: creationSourceId,
  authorName: idea.authorName,
  content: idea.content ?? '',
  image: idea.image,
  order: idea.order,
  quality: idea.quality,
  status: idea.status,
  title: idea.title,
  type: idea.type ?? IdeaType.DEFAULT,
});

/**
 * Makes the appropriate API call to sync the idea to the backend
 * @param idea The idea to be synced. Should always contain the localId of the idea, the id and it's creation status
 * If it's a creation it should be the whole local idea
 * It should have both the id and the local id whether it's an update or a creation to properly determine which one it is
 * @param sourceId The id of the source of the idea
 * @param onSuccess A callback to be made after the api calls. The params of the callback are the API response for idea creation.
 * If this is edit, only the ideaId will be echoed back
 * If this sync resulted in the creation of an UNKNOWN source, it will be returned through the respective param in the callback
 * @param onLoading A callback to signal the start of an API call
 * @return A pair holding True on the first position if the update should be re-queued
 * and True on the second position if a source was created
 */
export const syncIdeaUtil = async (
  idea: Partial<Idea>,
  userProfile: UserProfile,
  sourceId?: number,
  onSuccess?: (
    ideaId: number,
    created_at?: string,
    user?: string,
    source?: Source,
  ) => void,
  onLoading?: () => void,
) => {
  const localIdeaId = idea.localId;
  const ideaId = idea.id;

  // Check if the idea partial has the localId and the id included
  if (localIdeaId !== undefined && ideaId !== undefined) {
    // Check if the idea is already saved in the DB
    // If the idea exists in the backend, the id should be a positive number
    if (!isLocalIdea(ideaId)) {
      // It exists on the backend so it's an update
      onLoading?.();
      await editInsight(ideaId, idea);
      onSuccess?.(ideaId);
    } else if (idea.syncStatus === IdeaSyncStatus.SYNCING) {
      //A request is in progress. Don't do anything
      // Return that we should requeue this update to not lose data
      return [true, false];
    } else if (
      ((idea.type === IdeaType.IMAGE && !!idea.image) ||
        (idea.type !== IdeaType.IMAGE && ideaHasContent(idea.content ?? ''))) &&
      ideaIdManager.canPostId(localIdeaId)
    ) {
      // So this is a creation
      // The idea has content in it and it's the first time we make a request to create an idea on the backend with this local id. Thus we can proceed

      //The api request to create the idea will soon be initiated, so set the loading state
      onLoading?.();

      //Mark this idea as created on the backend to not send another POST
      ideaIdManager.markIdAsPosted(localIdeaId);

      if (sourceId) {
        //there is already a source created
        const { id, created_at, user } = await createInsight(
          getCreationPayload(sourceId, idea),
        );
        onSuccess?.(id, created_at, user);
      } else {
        //First create an UNKNOWN source and then send the idea creation API call
        const sourceApiResponse = await createUnknownSource();
        const source = normalizeUnknownSource(sourceApiResponse, userProfile);
        const { id, created_at, user } = await createInsight(
          getCreationPayload(source.id, idea),
        );
        onSuccess?.(id, created_at, user, source);
        return [false, true];
      }
    }
  }
  return [false, false];
};

/**
 * Makes the appropriate API call to sync a list of ideas to the backend
 * @param ideas The ideas to be synced
 * @param sourceId The id of the source of the idea
 * @param onSuccess A callback to be made after the creation api calls.
 * This callback is called for each created ideas. The params of the callback are the API response for idea creation.
 */
export const syncIdeasUtil = async ({
  ideas,
  sourceId,
  onSuccess,
}: {
  ideas: Idea[];
  sourceId: number;
  onSuccess?: (
    localIdeaId: number,
    ideaId: number,
    created_at: string,
    user?: string,
  ) => void;
}) => {
  const ideasToEdit: Idea[] = [];

  //Map ideas to their localIds. The api res will give back the ideas in the same order.
  // So the local id at ideasToCreateLocalIds[i] will correspond to ideasToCreate[i]
  const ideasToCreate: Idea[] = [];
  const ideasToCreateLocalIds: number[] = [];

  for (const idea of ideas) {
    if (!isLocalIdea(idea.id)) {
      // This idea already exists on the backend so this is an update
      ideasToEdit.push(idea);
    } else if (
      ((idea.type === IdeaType.IMAGE && !!idea.image) ||
        (idea.type !== IdeaType.IMAGE && ideaHasContent(idea.content))) &&
      ideaIdManager.canPostId(idea.localId)
    ) {
      // if it's a local idea but has content => add in creation queue
      ideaIdManager.markIdAsPosted(idea.localId);
      ideasToCreate.push(idea);
      ideasToCreateLocalIds.push(idea.localId);
    }
  }

  if (ideasToCreate.length !== 0) {
    const { blocks } = await createMultipleIdeas(sourceId, ideasToCreate);
    //Make the callback for all newly created ideas
    for (let i = 0; i < blocks.length; i++) {
      const { id, created_at, user } = blocks[i];
      const key = ideasToCreateLocalIds[i];
      onSuccess?.(key, id, created_at, user);
    }
  }

  await editMultipleIdeas(ideasToEdit);
};

/**
 * Function that determines if an idea is untouched
 * (no author, no title, no image, no content)
 * @param idea checked idea
 * @returns boolean
 */
export const isIdeaEmpty = (idea: Readonly<Idea>): boolean => {
  return (
    (idea.title === '' || !idea.title) &&
    (idea.authorName === '' || !idea.authorName) &&
    !ideaHasContent(idea.content) &&
    (!idea.image || idea.image === '')
  );
};

/**
 * Function that determines if an idea can be sent to the AI
 * (i.e. if it is a text-type idea and it has at least a title or content)
 * @param idea checked idea
 * @returns boolean
 */
export const isIdeaAiEligible = (idea: Idea): boolean => {
  return (
    ((idea.title?.length ?? 0) > 0 || ideaHasContent(idea.content)) &&
    idea.type === IdeaType.DEFAULT
  );
};

/**
 * Checks if an idea is just on local
 * @param ideaId id of the checked idea
 */
export const isLocalIdea = (ideaId: number): boolean => {
  // if the id is negative => the idea wasn't sent to the backend
  return ideaId < 0;
};

/**
 * A class that handles idea syncing
 * We want the saves to be debounced,
 * but if multiple sync requests for multiple ideas hit at the same time we don't want any of them do be dropped
 */
class DebounceIdeaSyncManager {
  /**
   * Maps idea keys to debounced sync requests
   */
  readonly #queuedSyncRequests: Map<
    number,
    { timeout: NodeJS.Timeout; timestamp: Date; patchedData?: Partial<Idea> }
  > = new Map();

  /**
   * Queues a sync request
   * If there was already a request made to sync this idea, the old one will be dropped and replaced with the new one
   * @param dispatch The dispatch function for the SourceProvider
   * @param idea The idea to be synced
   * @param sourceId The id of the source
   * @param patchedIdea When updating an idea already created we should send only the fields that changed. Provide those changes in this param
   * If this is a creation, this should be undefined since we have to send the full idea when creating it
   * @return A promise that resolves to whether or not a draft was created on the backend for this sync
   */
  syncIdea(
    dispatch: (action: SourceProviderHiddenAction) => void,
    idea: Idea,
    userProfile: UserProfile,
    updateTimestamp: Date,
    sourceId?: number,
    patchedIdea?: Partial<Idea>,
  ) {
    return new Promise(resolve => {
      const prevPendingUpdate = this.#queuedSyncRequests.get(idea.id);
      let fieldsToUpdate: Partial<Idea> = {};
      if (prevPendingUpdate && prevPendingUpdate.timestamp < updateTimestamp) {
        // If there is already a pending update for this idea, drop it
        // Load the fields to be sent to the backend from the previous update
        fieldsToUpdate = prevPendingUpdate.patchedData
          ? { ...prevPendingUpdate.patchedData }
          : {};
        clearTimeout(prevPendingUpdate.timeout);
      }
      // Update the fields to be sent to the backend with the latest ones
      fieldsToUpdate = patchedIdea
        ? { ...fieldsToUpdate, ...patchedIdea }
        : fieldsToUpdate;
      const timeoutId = setTimeout(() => {
        const draftCreated = syncIdea(dispatch, {
          idea: Object.keys(fieldsToUpdate).length
            ? {
                ...fieldsToUpdate,
                id: idea.id,
                localId: idea.localId,
                syncStatus: idea.syncStatus,
              }
            : idea,
          sourceId,
          userProfile,
          updateTimestamp,
        });
        resolve(draftCreated);
      }, 1300);
      this.#queuedSyncRequests.set(idea.id, {
        timeout: timeoutId,
        timestamp: updateTimestamp,
        patchedData: fieldsToUpdate,
      });
    });
  }
}

export const DebounceIdeaSyncManagerInstance = new DebounceIdeaSyncManager();
