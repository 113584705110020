import React, {
  ReactElement,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { Box, color, Flex, StyleProps, useColorMode } from 'deepstash-ui';
import { Source } from 'types/models';
import { IntersectionObserverContext } from 'src/providers/reads-autoincrement/IntersectionObserverProvider';
import IdeaCard from 'src/components/idea-card/IdeaCard';
import SourceCollapsed from '../source-collapsed/SourceCollapsed';

interface SourcePreviewMobileProps {
  source: Source;
  /**
   * The idea to show in this preview
   * If not specified, the first will be used
   */
  ideaId?: number;
  cornerElement?: ReactElement;
  /**
   * The mode of the header action bar
   * stash - Show the stash all button
   * none - Show nothing near the dropdown
   */
  headerMode: 'stash' | 'none';
  /**
   * Signal up the tree that this idea got stashed
   */
  onIdeaStashed?: () => void;
  /**
   * Signal up the tree that this idea got unstashed
   */
  onIdeaUnstashed?: () => void;
  /**
   * If this is true and the idea shown has an image, it will get preloaded
   */
  // [Like functionality]
  // onHeartLike?: () => void;
  // onHeartUnlike?: () => void;
  preloadIdeaImage?: boolean;
  /**
   *  Function that pushes the right URL to this source into the browser history
   */
  redirectToArticle?: (e: any) => void;
}

// eslint-disable-next-line react/display-name
const SourcePreviewMobile = React.forwardRef<
  HTMLDivElement | undefined,
  SourcePreviewMobileProps & StyleProps
>(
  (
    {
      source,
      ideaId,
      // cornerElement,
      // headerMode,
      onIdeaStashed,
      // onHeartLike,
      // onHeartUnlike,
      onIdeaUnstashed,
      preloadIdeaImage,
      redirectToArticle,
      ...props
    },
    ref,
  ) => {
    const { colorMode } = useColorMode();

    const rootRef = useRef<HTMLDivElement>();

    const previewIdea = useMemo(
      () => source.ideas.find(idea => idea.id === ideaId) ?? source.ideas[0],
      [source, ideaId],
    );

    const { setInsights } = useContext(IntersectionObserverContext);
    useEffect(() => {
      setInsights([previewIdea]);
    }, [previewIdea, setInsights]);
    const imperativeHandleCbk = () => rootRef.current;
    useImperativeHandle(ref, imperativeHandleCbk);

    return (
      <Box
        gridColumn={'span 4 / span 4'}
        position="relative"
        {...props}
        ref={el => (rootRef.current = el ?? undefined)}
        px="1rem"
        py="1rem"
        onClick={redirectToArticle}
      >
        <Box
          backgroundColor={color[colorMode].surface}
          _hover={undefined}
          pos="absolute"
          h="calc(100% + 1rem)"
          w="calc(100% + 2rem)"
          ml="-1rem" //Cover the paddingLeft of the layout
        />
        <Flex flexDir="column" pb={0} position="relative">
          <SourceCollapsed source={source} size="small" />
          <IdeaCard
            ideaData={{ idea: previewIdea, source }}
            onIdeaStashed={onIdeaStashed}
            onIdeaUnstashed={onIdeaUnstashed}
            // [Like functionality]
            // onHeartLike={onHeartLike}
            // onHeartUnlike={onHeartUnlike}
            preloadImage={preloadIdeaImage}
            pos="relative"
          />
        </Flex>
      </Box>
    );
  },
);

export default SourcePreviewMobile;
