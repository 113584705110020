import {
  Box,
  color,
  Flex,
  spacing,
  StyleProps,
  useColorMode,
  useIsMobileView,
} from 'deepstash-ui';
import dynamic from 'next/dynamic';
import React from 'react';
import useActivity from 'src/providers/hooks/useActivity';
import useAuth from 'src/providers/hooks/useAuth';
import { IdeaType } from 'types/enums';
import { Idea, Source } from 'types/models';
import { getRemWidthFromColumns } from 'utils/global';
import BaseCard from '../cards/BaseCard';
import FlipCorner, {
  FLIP_CORNER_EDGE_SIZE_BASE,
  FLIP_CORNER_EDGE_SIZE_LG,
} from './components/FlipCorner';
import { useIdeaCardInternals } from './useIdeaCardInternals';
import IdeaCardContentImage from './components/IdeaCardContentImage';

const IdeaCardActionBar = dynamic(
  () => import('./components/IdeaCardActionBar'),
);

const IdeaCardContentQuote = dynamic(
  () => import('./components/IdeaCardContentQuote'),
);

const IdeaCardContentText = dynamic(
  () => import('./components/IdeaCardContentText'),
);

/**
 * number == idea id, when is current user's idea,
 * {idea, source} == other user's idea
 */
export type IdeaData = number | { idea: Idea; source: Source | null };

interface IdeaCardProps {
  /**
   * If is a current user idea, pass the key, otherwise pass the idea and source wrapped in an object
   *
   * @type @number | {idea: Idea; source: Source }
   */
  ideaData: IdeaData;
  /**
   * Whether or not we want the image of this idea to be preloaded
   */
  preloadImage?: boolean;
  /**
   * Signal up the tree that this idea got stashed
   */
  onIdeaStashed?: () => void;
  /**
   * Signal up the tree that this idea got unstashed
   */
  onIdeaUnstashed?: () => void;
  /**
   * Whether to have a static ideaCard that is not interactable
   */
  isNotInteractable?: boolean;
  /** If true, don't show the image */
  hideImage?: boolean;
  /**
   * If true, disable href on title
   */
  disableTitleHref?: boolean;
}

// eslint-disable-next-line react/display-name
const IdeaCard = React.forwardRef<HTMLDivElement, IdeaCardProps & StyleProps>(
  (
    {
      ideaData,
      preloadImage,
      onIdeaStashed,
      onIdeaUnstashed,
      isNotInteractable,
      hideImage,
      disableTitleHref,
      ...props
    },
    ref,
  ) => {
    const isMobileView = useIsMobileView();
    const { colorMode } = useColorMode();

    const { headingSize, idea, source, shouldIdeaBeTrimmed } =
      useIdeaCardInternals({
        data: ideaData,
      });

    const { activity } = useActivity();

    const { isLoggedIn } = useAuth();
    const saved =
      isLoggedIn && activity?.stashedIdeasIds?.includes(idea?.id || -1);

    if (!idea) return null;

    const mainContainerStyling: StyleProps = {
      w: { base: '100%', md: getRemWidthFromColumns(4) },
      pos: 'relative', // flip corner has pos absolute, and it will be positioned relative to it's first ancestor
      // with no static pos (the default value), that's why IdeaCard should have pos: relative.
      borderRadius: '1.25rem',
      // borderTopRightRadius: saved ? '0' : '1.25rem',
      flexDir: 'column',
      minH: spacing.toRem(300),
      // We have this willChange prop to fix rendering issues on Safari
      willChange: 'filter',
      ...props,
    };

    const getClipPath = (size: string) =>
      `polygon(0% 0, 0 0%, calc(100% - ${size}) 0%, 100% ${size}, 100% 100%, 100% 100%, 0 100%, 0 100%);`;

    return (
      <Flex
        {...mainContainerStyling}
        data-testid="idea-card"
        className="insight"
        id={`idea-${idea.id}`}
        ref={ref} // This ref is used for pushing ideas in a ref[] array for the scroll to n'th idea feature
        {...props}
      >
        <BaseCard
          pb={{ base: '1rem', lg: '1.25rem' }}
          flexDir="column"
          justifyContent="space-between"
          overflow="hidden"
          flexGrow={1}
          // We cut from the top-right corner of the card when saved
          clipPath={{
            base:
              !isNotInteractable && saved
                ? getClipPath(FLIP_CORNER_EDGE_SIZE_BASE)
                : '',
            lg:
              !isNotInteractable && saved
                ? getClipPath(FLIP_CORNER_EDGE_SIZE_LG)
                : '',
          }}
          backgroundColor={props.backgroundColor ?? color[colorMode].top}
        >
          <FlipCorner
            id={idea.id}
            isNotInteractable={isNotInteractable}
            foldedRadiusSize={isMobileView ? '0.5rem' : '1rem'}
          />
          <Box
            pos="relative"
            // Center the content if it's quote without image
            height={
              idea.type === IdeaType.QUOTE && !idea.image ? '100%' : 'unset'
            }
            my={idea.type === IdeaType.QUOTE ? 'auto' : 'unset'}
          >
            {idea.type === IdeaType.QUOTE ? (
              <IdeaCardContentQuote
                headingSize={headingSize}
                idea={idea}
                preloadImage={preloadImage}
                isNotInteractable={isNotInteractable}
                shouldTrim={shouldIdeaBeTrimmed}
                disableTitleHref={disableTitleHref}
              />
            ) : idea.type === IdeaType.DEFAULT ? (
              <IdeaCardContentText
                headingSize={headingSize}
                idea={idea}
                preloadImage={preloadImage}
                isNotInteractable={isNotInteractable}
                shouldTrim={shouldIdeaBeTrimmed}
                hideImage={hideImage}
                disableTitleHref={disableTitleHref}
              />
            ) : (
              <IdeaCardContentImage
                headingSize={headingSize}
                idea={idea}
                preloadImage={preloadImage}
                isNotInteractable={isNotInteractable}
                shouldTrim={shouldIdeaBeTrimmed}
                hideImage={hideImage}
                disableTitleHref={disableTitleHref}
              />
            )}
          </Box>
          {!isNotInteractable && (
            <Box
              onClick={ev => ev.stopPropagation()}
              px={{ base: '1.5rem', lg: '2rem' }}
            >
              <IdeaCardActionBar
                source={source}
                onIdeaStashed={onIdeaStashed}
                onIdeaUntashed={onIdeaUnstashed}
                idea={idea}
              />
            </Box>
          )}
        </BaseCard>
      </Flex>
    );
  },
);
export default IdeaCard;
