import { StyleProps, useIsMobileView } from 'deepstash-ui';
import { ArticlesResponse } from 'api/api.types';
import { useIsIdeaStashed } from 'hooks/idea/useIsIdeaStashed';
import dynamic from 'next/dynamic';
import useRouter from 'hooks/useRouter';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Source } from 'types';
import { slugify } from 'utils/global';
import SourcePreviewMobile from './SourcePreviewMobile';
import { MutateCallback } from 'hooks/useInfiniteScroll';

const SourcePreviewDesktop = dynamic(() => import('./SourcePreviewDesktop'));
SourcePreviewDesktop.displayName = 'SourcePreviewDesktop';

export interface SourcePreviewProps {
  source: Source;

  /**
   * The idea to show in this preview
   * If not specified, the first will be used
   */
  ideaId?: number;
  cornerElement?: ReactElement;
  /**
   * The mode of the header action bar
   * stash - Show the stash all button
   * none - Show nothing near the dropdown
   *
   * Defaults to stash
   */
  headerMode?: 'stash' | 'none';
  /**
   * If this is true and the idea shown has an image, it will get preloaded
   */
  preloadIdeaImage?: boolean;
  /**
   * Mutate for the current infiniteScroll
   */
  apiDataMutate?: (data: MutateCallback<ArticlesResponse>) => void;
}

// eslint-disable-next-line react/display-name
const SourcePreview = React.forwardRef<
  HTMLDivElement | undefined,
  SourcePreviewProps & StyleProps
>(
  (
    {
      source: sourceProp,
      ideaId,
      cornerElement,
      apiDataMutate,
      headerMode = 'stash',
      ...props
    },
    ref,
  ) => {
    const isMobileView = useIsMobileView();
    const router = useRouter();
    const [source, setSource] = useState(sourceProp);

    useEffect(() => {
      setSource(sourceProp);
    }, [sourceProp]);

    const childRef = useRef<HTMLDivElement>();
    const imperativeHandleCbk = () => childRef.current;
    useImperativeHandle(ref, imperativeHandleCbk);

    const redirectToArticle = useCallback(
      e => {
        const url = `/article/${source.id}/${slugify(source.title)}`;

        e.stopPropagation();
        if ((window.getSelection()?.toString() ?? '') === '' && url) {
          // the url we clicked on is valid
          if (e.metaKey === false || e.ctrlKey === false) {
            // we only left clicked
            // metaKey it's actually ctrlKey for the macintosh
            router.push(url);
          } else if (typeof window !== 'undefined') {
            // we click + control ( + cmd on Safari )
            window.open(url, '_blank')?.focus();
          }
        }
      },
      [source.title, source.id],
    );

    //Increment the total stash count for the idea live
    const ideaToShowId = ideaId ?? source.ideas[0]?.id;

    const isIdeaStashed = useIsIdeaStashed(ideaToShowId);

    const onIdeaStash = useCallback(() => {
      if (!isIdeaStashed) {
        setSource(prevSource => {
          //Increment the total stashed for the sown idea
          const ideaIndex = prevSource.ideas.findIndex(
            ({ id }) => id === ideaToShowId,
          );
          const ideas = [...prevSource.ideas];
          ideas[ideaIndex].totalSaves++;
          return { ...prevSource, ideas };
        });
      }
    }, [ideaToShowId, isIdeaStashed]);

    const onIdeaUnstash = useCallback(() => {
      if (isIdeaStashed) {
        setSource(prevSource => {
          //Increment the total stashed for the sown idea
          const ideaIndex = prevSource.ideas.findIndex(
            ({ id }) => id === ideaToShowId,
          );
          const ideas = [...prevSource.ideas];
          ideas[ideaIndex].totalSaves--;
          return { ...prevSource, ideas };
        });
      }
    }, [ideaToShowId, isIdeaStashed]);

    // [Like functionality]
    // const onHeartLike = useCallback(() => {
    //   setSource(prevSource => {
    //     const ideaIndex = prevSource.ideas.findIndex(
    //       ({ id }) => id === ideaToShowId,
    //     );
    //     const ideas = [...prevSource.ideas];
    //     ideas[ideaIndex].totalLikes++;
    //     return { ...prevSource, ideas };
    //   });
    // }, []);

    // const onHeartUnlike = useCallback(() => {
    //   setSource(prevSource => {
    //     const ideaIndex = prevSource.ideas.findIndex(
    //       ({ id }) => id === ideaToShowId,
    //     );
    //     const ideas = [...prevSource.ideas];
    //     ideas[ideaIndex].totalLikes--;
    //     return { ...prevSource, ideas };
    //   });
    // }, []);

    if (source.ideas.length === 0) {
      return null;
    }

    if (!source) return null;

    return isMobileView ? (
      <SourcePreviewMobile
        source={source}
        ideaId={ideaId}
        ref={ref}
        cornerElement={cornerElement}
        onIdeaStashed={onIdeaStash}
        onIdeaUnstashed={onIdeaUnstash}
        // [Like functionality]
        // onHeartLike={onHeartLike}
        // onHeartUnlike={onHeartUnlike}
        headerMode={headerMode}
        redirectToArticle={redirectToArticle}
        {...props}
      />
    ) : (
      <SourcePreviewDesktop
        redirectToArticle={redirectToArticle}
        source={source}
        ideaId={ideaId}
        cornerElement={cornerElement}
        headerMode={headerMode}
        onIdeaStashed={onIdeaStash}
        onIdeaUnstashed={onIdeaUnstash}
        // [Like functionality]
        // onHeartLike={onHeartLike}
        // onHeartUnlike={onHeartUnlike}
        apiDataMutate={apiDataMutate}
        ref={ref}
        {...props}
      />
    );
  },
);

export default SourcePreview;
